<template>
    <div>
        <v-text-field dense outlined label="Codigo Barra" :append-outer-icon="icons.mdiBarcodeScan"
            @click:append-outer="abrirModal()" v-model="codigo" v-on:input="handleInput" :rules="rules">
        </v-text-field>

        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Generar nueva barra
                </v-card-title>

                <v-card-text>
                    <v-select v-model="tipoBarra" :items="list" item-value="id" item-text="nombre" label="Tipo Barrra">
                        <template slot="selection" slot-scope="data">

                            {{ data.item.nombre }} (GTIN{{ data.item.gtin }})

                        </template>
                        <template slot="item" slot-scope="data">

                            {{ data.item.nombre }} (GTIN{{ data.item.gtin }})

                        </template>
                    </v-select>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="GenerarCodigo()">
                        Generar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>


</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiBarcodeScan } from '@mdi/js'
import ProductoServices from '@/api/servicios/ProductoServices'
import store from '@/store'
export default {
    props: {
        value: String,
        rules: Array
    },
    setup(props, contex) {



        const dialog = ref(false)
        const list = ref([])
        const abrirModal = () => {
            dialog.value = true
        }
        const codigo = ref("")
        const tipoBarra = ref(null)

        const cargando = ref(false)
        watch( props, () => { 
            codigo.value = props.value
        }

        )
        onBeforeMount(() => {
            cargarDatos()
        })
        const GenerarCodigo = () => {

            cargando.value = true
            try {
                ProductoServices.GenerarCodigoBarra(
                    store.state.user.IdUsuario,
                    tipoBarra.value
                )
                    .then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            codigo.value = response.data.datos
                            contex.emit('input', codigo.value)
                            dialog.value = false
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargando.value = false
                    })

            } catch (error) {
                cargando.value = false
                //alert(error)
            }
        }

        const cargarDatos = () => {

            list.value = []
            cargando.value = true
            try {
                ProductoServices.ConsultarContadorCodigoBarra()
                    .then(response => {
                        console.log("ConsultarContadorCodigoBarra")
                        console.log(response)
                        if (response.data.estatus == true) {
                            list.value = response.data.datos
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargando.value = false
                    })

            } catch (error) {
                cargando.value = false
            }
        }

        const handleInput = (dato) => {

            contex.emit('input', dato)
        }



        return {
            icons: {
                mdiBarcodeScan
            },
            dialog,
            abrirModal,
            cargarDatos,
            tipoBarra,
            list,
            handleInput,
            GenerarCodigo,
            codigo
        }
    },
}
</script>