<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6" class="ma-0 pt-6">
        <v-row>
          <v-btn
            color="success"
            class="mx-1 my-1"
            small
            v-if="exportar.includes('EXCEL')"
            @click="exportarExcelEmit()"
            :disabled="datos != null && datos.length == 0"
          >
            EXCEL
            <v-icon small>{{ icons.mdiFileExcel }}</v-icon>
          </v-btn>
          <v-btn
            color="error"
            small
            class="mx-1 my-1"
            v-if="exportar.includes('PDF')"
            :disabled="datos != null && datos.length == 0"
            @click="ImprimirPDF()"
          >
            PDF
            <v-icon small>{{ icons.mdiFilePdf }}</v-icon>
          </v-btn>
          <div class="mx-1 mt-1">
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="secondary" small v-bind="attrs" v-on="on"> Columnas </v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-list-item-group v-model="columasSelect" multiple style="height: 200px; overflow-y: auto;" >
                    <template v-for="(item, i) in headers">
                      <v-list-item :key="`item-${i}`" :value="item" active-class="primary--text  ">
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-checkbox :input-value="active" color="primary  "> </v-checkbox>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-row>
      </v-col>
      <!-- <v-col cols="12" sm="12" md="6">
            <v-text-field
            clearable
            outlined
            dense
            v-model="search"
            :append-icon="icons.mdiMagnify"
            label="Filtrar"
            v-if="datos.length > 0"
            single-line
            hide-details
            >
            </v-text-field>
        </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-progress-linear v-if="cargando" indeterminate height="1" color="primary"></v-progress-linear>
        <v-simple-table :dense="dense">
          <template v-slot:default>
            <thead>
              <th
                v-for="(item, i) in columasSelect"
                :key="i"
                :style="`   ${item.width != undefined ? `min-width: ${item.width}  !important;`:''}`"
                :class="  'py-2 pl-4 ' + (item.align   ? ('text-' + item.align ): 'text-left' ) "
              >
                <small>  {{ item.text }}</small>
              </th>
            </thead>
            <tbody>
              <tr v-for="item in datos" :key="item.value">
                <td 
                  v-for="col in columasSelect"
                  :key="col.value"
                  :class="col.align ? 'text-' + col.align : '' + col.value == 'accion' ? 'fixed' : ''"

                 
                >
                  <div v-if="col.value == 'accion'" style="width: 100px; text-align: center">
                    <v-tooltip bottom v-if="acciones.includes('VER')">
                      <template v-slot:activator="{ on, attrs }">
                        <a class="pr-2" v-bind="attrs" v-on="on" @click="GetVer(item)">
                          <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                        </a>
                      </template>
                      <span>Ver</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="acciones.includes('EDITAR')">
                      <template v-slot:activator="{ on, attrs }">
                        <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditar(item)">
                          <v-icon color="info">{{ icons.mdiPencil }}</v-icon>
                        </a>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="acciones.includes('ELIMINAR')">
                      <template v-slot:activator="{ on, attrs }">
                        <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEliminar(item)">
                          <v-icon color="error">{{ icons.mdiDelete }}</v-icon>
                        </a>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                    <span v-for="(accion, index) in otrasAcciones" :key="index">
                      <v-tooltip
                        bottom
                        v-if="accion.ifValor && accion.ifValor.valor == findObjectByLabel(item, accion.ifValor.columna)"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <a class="pr-2" v-bind="attrs" v-on="on" @click="GetOtrasAcciones(item, accion.emit)">
                            <v-icon :color="accion.color">{{ accion.icon }}</v-icon>
                          </a>
                        </template>
                        <span>{{ accion.nombre }}</span>
                      </v-tooltip>
                    </span>
                  </div>
                  <span v-if="col.format == undefined">{{ findObjectByLabel(item, col.value) }}</span>
                  <small v-else-if="col.format.small == true">{{ findObjectByLabel(item, col.value) }}</small>
                  <span v-else-if="col.format.numeric == true">{{
                    FuncionesGenerales.formatoNumeric(findObjectByLabel(item, col.value), 1)
                  }}</span>
                  <span v-else-if="col.format.date == true">{{
                    FuncionesGenerales.formatoFecha(findObjectByLabel(item, col.value), 1)
                  }}</span>
                  <span v-else-if="col.format.dateTime == true">{{
                    FuncionesGenerales.formatoFecha(findObjectByLabel(item, col.value), 3)
                  }}</span>
                  <span v-else-if="col.format.time == true">{{
                    FuncionesGenerales.formatoFecha(findObjectByLabel(item, col.value), 2)
                  }}</span>
                  <v-chip
                    v-else-if="col.format.chip == true"
                    class="ma-2"
                    :color="col.format.color[col.format.valores.indexOf(findObjectByLabel(item, col.value))]"
                    :small="col.format.size == 'small'"
                    :x-small="col.format.size == 'x-small'"
                  >
                    {{ findObjectByLabel(item, col.value) }}
                  </v-chip>
                  <div v-else-if="col.format.icon" class="d-flex align-center">
                    <v-icon :color="col.format.color[col.format.valores.indexOf(findObjectByLabel(item, col.value))]">
                      {{ col.format.icons[col.format.valores.indexOf(findObjectByLabel(item, col.value))] }}
                    </v-icon>
                  </div>
                </td>
              </tr>
              <tr v-if="datos == null || datos.length == 0">
                <td class="text-center" :colspan="headers.length">No existen resultados asociados a la consulta</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" md="2">
        <div class="text-right">
          <v-select
            :items="[10, 20, 30, 40]"
            :disabled="cargando"
            v-model="Limite"
            label="Standard"
            dense
            outlined
            @change="changeOpciones"
          ></v-select>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <v-pagination
          :total-visible="7"
          :disabled="cargando"
          v-model="PaginaActual"
          :length="Paginas"
           
        ></v-pagination>
        <!-- {{ paginas }} -->
      </v-col>
      <v-col cols="12" md="2">
        <div class="text-right mt-2">Total Registros {{ Cantidad }}</div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { computed, onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiFileExcel, mdiFilePdf, mdiMagnify, mdiPencil, mdiDelete, mdiEye, mdiCheck, mdiCancel } from '@mdi/js'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from './Pdf.vue'
export default {
  components: {
    Pdf,
  },
  props: {
    exportar: Array,
    datosIn: Array,
    nombreReporte: String,
    cabeceraIn: Array,
    acciones: Array,
    otrasAcciones: Array, //{nombre:"",Icon:icon,emit:"",Color:""}
    cargando: Boolean,
    dense: {
      type:Boolean,
      default:true
    },
    Paginas: {
      type: Number,
      default: 0,
    },
    Cantidad: {
      type: Number,
      default: 0,
    },
    opciones: {
      type: Object,
      default: {
        Pagina: 1,
        LimiteFilas: 10,
      },
    },
  },
  watch: {
    PaginaActual: function (news,old) {
        
      if (old != null) {
        this.changeOpciones()
        
      }
    },
  },
  setup(props, context) {
    const menu = ref(null)
    const headers = ref([])
    const datos = ref()
    const pdfRef = ref(null)
    const columasSelect = ref([])

    const Limite = ref()
    const PaginaActual = ref(null)

    var findObjectByLabel = function (obj, label) {
      if (label.includes('.')) {
        let array = label.split('.')
        const primero = array[0]
        array = array.splice(1).join('.')
        var foundLabel = findObjectByLabel(obj[primero], array)
        if (foundLabel) {
          return foundLabel
        }
      } else {
        if (obj != null) {
          return obj[label]
        } else {
          return ''
        }
      }
    }
    const exportandoExel = ref(false)
    const search = ref('')
    var inicizando = true
    const exportarDataExcel = () => {
      console.log(columasSelect.value)

      let data = []
      datos.value.forEach(element => {
        let objet = {}
        columasSelect.value.forEach(columna => {
          let valor = findObjectByLabel(element, columna.value)
          if (typeof valor === 'number') {
            objet = { ...objet, [columna.text]: valor.toString().replace('.', ',') }
          } else {
            objet = { ...objet, [columna.text]: valor }
          }
        })
        data.push(objet)
      })
      console.log(data)
      return data
    }
    watch(props, () => {
      //headers.value = props.cabeceraIn
      // columasSelect.value = props.cabeceraIn.filter(item => item.visible)
      datos.value = props.datosIn
    })

    onBeforeMount(() => {
      inicizando = true
      headers.value = props.cabeceraIn
      datos.value = props.datosIn
      columasSelect.value = props.cabeceraIn.filter(item => item.visible)

      Limite.value = props.opciones.LimiteFilas
      PaginaActual.value = props.opciones.Pagina

      inicizando = false
    })

    const GetEditar = item => {
      context.emit('GetEditar', item)
    }

    const GetVer = item => {
      context.emit('GetVer', item)
    }
    const GetEliminar = item => {
      context.emit('GetEliminar', item)
    }
    const GetOtrasAcciones = (item, emit) => {
      context.emit(emit, item)
    }

    const changeOpciones = () => {
      context.emit('getOpciones', {
        Pagina: PaginaActual.value,
        LimiteFilas: Limite.value,
      })
    }

    const ImprimirPDF = () => {
      const bodyPdf = []
      const headPdf = []

      columasSelect.value.forEach(element => {
        headPdf.push({ content: element.text })
      })

      datos.value.forEach(element => {
        let arr = []
        columasSelect.value.forEach(columna => {
          arr.push(findObjectByLabel(element, columna.value))
        })

        bodyPdf.push(arr)
      })

      const datosInit = {
        nombreDocumento: props.nombreReporte,
        orientacion: 'l',
        cabecera1: {
          visible: true,
        },
        titulo: {
          visible: true,
          posicion: 'center',
          texto: props.nombreReporte,
          TamanoLetras: 12,
          color: '#7B7B7B',
        },
        cabecera2: {
          visible: false,
          datos: [{ texto: 'Recibo #', TamanoLetras: 14, color: '#FF0000' }, { texto: 'Fecha: ' }, { texto: 'Hora: ' }],
        },
        sub1: {
          visible: false,
          datos: [],
        },
        sub2: {
          visible: false,
          datos: [],
        },
        cuerpo1: {
          visible: true,
          datos: [
            {
              titulo: { texto: 'Datos', TamanoLetras: 12 },
              tablas: {},
              head: [headPdf],
              body: bodyPdf,
            },
          ],
        },
      }
      pdfRef.value.generarPDF(datosInit)
    }

    const pagination = ref({}) // <-- holds our pagination data

    const exportarExcelEmit = () => {
      var cabeceraBd = columasSelect.value
        .filter(x => x.value != 'accion')
        .map(x => x.columnaDB)
        .join()
      context.emit('exportExcel', cabeceraBd)
    }
    return {
      GetOtrasAcciones,
      ImprimirPDF,
      findObjectByLabel,
      exportarDataExcel,
      GetEditar,
      GetVer,
      GetEliminar,
      exportarExcelEmit,
      changeOpciones,
      pagination,
      headers,
      datos,
      columasSelect,
      menu,
      exportandoExel,
      search,
      pdfRef,
      FuncionesGenerales,
      PaginaActual,
      Limite,
      inicizando,
      icons: {
        mdiFileExcel,
        mdiFilePdf,
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiEye,
        mdiCheck,
        mdiCancel,
      },
    }
  },
}
</script>
 

<style>
.fixed {
  position: sticky;
  width: 5em;
  left: 0;
  top: auto;
  background-color: white;
  z-index: 2;
}

/* each row block css in mobile*/
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  margin: 10px;
  border: 1px solid #ededed;
  display: block;
}

/* each cell border in mobile */
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  border-bottom: 1px solid #ededed;
}
</style>