<template>
  <div>
    <v-card>
      <v-card-text>
        <v-expand-transition>
          <div v-show="showFiltros">
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3">
                <TipoOrigenProdcutoSelect v-model="filtro.IdTipoOrigenProducto" :clearable="true" :outlined="true">
                </TipoOrigenProdcutoSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <TipoProdcutoSelect v-model="filtro.IdTipoProducto" :clearable="true" :outlined="true">
                </TipoProdcutoSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <TasaImpuestoSelect v-model="filtro.IdTasaImpuesto" :padre="1" :clearable="true" :outlined="true">
                </TasaImpuestoSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <UnidadMedidaSelect v-model="filtro.IdUnidadMedida" :clearable="true" :outlined="true" :add="false">
                </UnidadMedidaSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <MarcaSelect v-model="filtro.IdMarca" :clearable="true" :outlined="true" :add="false"> </MarcaSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <ModeloSelect :clearable="true" :outlined="true" :add="false"> </ModeloSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <ColorSelect v-model="filtro.IdColor" :clearable="true" :outlined="true" :add="false"> </ColorSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <CategoriaSelect v-model="filtro.IdCategoria" :clearable="true" :outlined="true" :add="false">
                </CategoriaSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <SubCategoriaSelect
                  v-model="filtro.IdSubCategoria"
                  :disabled="filtro.IdCategoria == null ? true : false"
                  :padre="filtro.IdCategoria"
                  :clearable="true"
                  :outlined="true"
                  :add="false"
                >
                </SubCategoriaSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <LineaSelect v-model="filtro.IdLinea" :clearable="true" :outlined="true" :add="false"> </LineaSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <SubLineaSelect
                  v-model="filtro.IdSubLinea"
                  :disabled="filtro.IdLinea == null ? true : false"
                  :padre="filtro.IdLinea"
                  :clearable="true"
                  :outlined="true"
                  :add="false"
                ></SubLineaSelect>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <PaisSelect v-model="filtro.IdPais" :clearable="true" :outlined="true"> </PaisSelect>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
        <v-row>
          <v-col cols="12" md="6" lg="6">
            <v-row>
              <v-col cols="12" md="10" lg="10">
                <v-text-field
                  ref="NombreRef"
                  dense
                  outlined
                  label="Descripcion"
                  v-model="filtro.Valor" 
                  placeholder="Nombre/Descripcion/Barra/Ref..."
                  v-on:keyup.enter="consultarDatos()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" lg="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-1 my-1"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="showFiltros = !showFiltros"
                      icon
                      tile
                    >
                      <v-icon>
                        {{ !showFiltros ? icons.mdiFilter : icons.mdiFilterOff }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Filtros</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="text-right">
            <v-btn :loading="exportandoDatos" color="info" class="mr-2" small @click="abrirModalSucursal()">
              <v-icon class="mr-2"> {{ icons.mdiDatabaseExport }}</v-icon> Exportar Data</v-btn
            >
            <v-btn color="sencodary" class="mx-1 my-1" small @click="Limpiar()" :disabled="cargando"> Limpiar</v-btn>
            <v-btn color="primary" class="mx-1 my-1" small @click="consultarDatos()" :loading="cargando"> Buscar</v-btn>
          </v-col>
        </v-row>
        <TablaPaginadaReporte
          @GetEditar="GetEditar"
          @getOpciones="getOpciones" 
          @exportExcel="exportExcel"
          :acciones="['EDITAR']"
          :nombreReporte="nombreLista"
          :cabeceraIn="cabecera"
          :datosIn="lista"
          :exportar="['EXCEL']"
          :otrasAcciones="otrasAcciones"
          :Cantidad="Cantidad" 
          :Paginas="Paginas"
          :cargando="cargando"
        ></TablaPaginadaReporte>
      </v-card-text>
      <SelectModal @input="SelectModalValue" ref="SelectModalRef" />
    </v-card>
  </div>
</template>
<script>
import UnidadMedidaSelect from '@/views/sistema/producto/maestro/unidad-medida/componentes/Select.vue'
import MarcaSelect from '@/views/sistema/producto/maestro/marca/componentes/Select.vue'
import ModeloSelect from '@/views/sistema/producto/maestro/modelo/componentes/Select.vue'
import ColorSelect from '@/views/sistema/producto/maestro/color/componentes/Select.vue'
import CategoriaSelect from '@/views/sistema/producto/maestro/categoria/componentes/Select.vue'
import SubCategoriaSelect from '@/views/sistema/producto/maestro/sub-categoria/componentes/Select.vue'
import LineaSelect from '@/views/sistema/producto/maestro/linea/componentes/Select.vue'
import SubLineaSelect from '@/views/sistema/producto/maestro/sub-linea/componentes/Select.vue'
import PaisSelect from '@/views/sistema/producto/maestro/pais/componentes/Select.vue'
import TipoOrigenProdcutoSelect from '@/views/sistema/producto/maestro/tipo-origen-producto/componentes/Select.vue'
import TipoProdcutoSelect from '@/views/sistema/producto/maestro/tipo-producto/componentes/Select.vue'
import TasaImpuestoSelect from '@/views/sistema/producto/maestro/tasa-impuesto/componentes/Select.vue'
import ProveedorSelect from '@/views/sistema/producto/catalogo/proveedor/componentes/Select.vue'
import SelectModal from '@/views/sistema/configuracion/sucursal/componentes/SelectModal.vue'
import { computed, onBeforeMount, ref, watch } from '@vue/composition-api'
import ProductoServices from '@/api/servicios/ProductoServices'
import config from '@/api/config'
import { mdiPencil, mdiCheck, mdiCancel, mdiFilterOff, mdiFilter,mdiDatabaseExport } from '@mdi/js'
import store from '@/store'
import moment from 'moment'
import TablaPaginadaReporte from '@/components/TablaPaginadaReporte.vue'
import fileDownload from 'js-file-download'
import FuncionesGenerales from '@/funciones/funciones'
import ExportImportServices from '@/api/servicios/ExportImportServices'
export default {
  components: {
    UnidadMedidaSelect,
    MarcaSelect,
    ModeloSelect,
    ColorSelect,
    CategoriaSelect,
    SubCategoriaSelect,
    LineaSelect,
    SubLineaSelect,
    PaisSelect,
    TipoOrigenProdcutoSelect,
    TipoProdcutoSelect,
    TasaImpuestoSelect,
    ProveedorSelect,
    TablaPaginadaReporte,
    SelectModal
  },
  setup(props, context) {
    const Paginas = ref(0)
    const Pagina = ref(1)
    const LimiteProps = ref(10)
    const Cantidad = ref(0)
    const showFiltros = ref(false)
    const NombreRef = ref(null)
    const otrasAcciones = [
      {
        nombre: 'Activar',
        icon: mdiCheck,
        emit: 'GetBtn',
        color: 'success',
        ifValor: { columna: 'indActivo', valor: false },
      },
      {
        nombre: 'Desactivar',
        icon: mdiCancel,
        emit: 'GetBtn',
        color: 'error',
        ifValor: { columna: 'indActivo', valor: true },
      },
    ]
    const nombreLista = ref(`Lista de productos`)
    const cabecera = ref([
      { text: 'Acción', value: 'accion', visible: true, columnaDB: '', with: '200px', fixed: true },
      { text: 'ID', value: 'id', visible: true, columnaDB: 'Producto.Id As ID' },
      { text: 'Nombre', value: 'nombre', visible: true, columnaDB: 'Producto.nombre As Nombre' },
      { text: 'Código', value: 'barra', visible: true, columnaDB: 'Producto.barra As Barra' },
      { text: 'Referencia', value: 'referencia', visible: true, columnaDB: 'Producto.referencia As Referencia' },
      {
        text: 'Referencia de fábrica',
        value: 'referenciaFabrica',
        visible: true,
        columnaDB: 'Producto.referenciaFabrica As [Referencia de fábrica]',
      },
      {
        text: 'Origen producto',
        value: 'tipoOrigenProducto.descripcion',
        visible: true,
        columnaDB: 'TipoOrigenProducto.descripcion As [Origen de producto]',
      },
      {
        text: 'Tipo de producto',
        value: 'tipoProducto.descripcion',
        visible: true,
        columnaDB: 'tipoProducto.descripcion As [Tipo de  producto]',
      },
      {
        text: 'Unidad  de medida',
        value: 'unidadMedida.nombre',
        visible: true,
        columnaDB: 'unidadMedida.nombre As [Tipo de  Unidad de medida]',
      },
      { text: 'Marca', value: 'marca.descripcion', visible: true, columnaDB: 'Marca.descripcion As Marca' },
      { text: 'Color', value: 'color.descripcion', visible: false, columnaDB: 'color.descripcion As Color' },
      { text: 'Modelo', value: 'modelo.descripcion', visible: true, columnaDB: 'Modelo.descripcion As Modelo' },
      {
        text: 'Categoría',
        value: 'categoria.descripcion',
        visible: true,
        columnaDB: 'Categoria.descripcion As Categoría',
      },
      {
        text: 'Sub Categoría',
        value: 'subCategoria.descripcion',
        visible: true,
        columnaDB: 'SubCategoria.descripcion As [Sub Categoría]',
      },
      { text: 'Linea', value: 'linea.descripcion', visible: false, columnaDB: 'Linea.descripcion As Linea' },
      {
        text: 'Sub Linea',
        value: 'subLinea.descripcion',
        visible: false,
        columnaDB: 'SubLinea.descripcion As [Sub Linea]',
      },
      { text: 'País', value: 'pais.nombre', visible: false, columnaDB: 'Pais.nombre As Sub País' },
    ])

    const GetEditar = item => {
      context.emit('GetEditar', item)
    }
    const SelectModalRef = ref()
    const abrirModalSucursal = () => {
      SelectModalRef.value.abrirDialog()
    }
    
    const exportandoDatos = ref(false)
    const SelectModalValue = item => {
      exportandoDatos.value = true
      ExportImportServices.ExportarProductoSucursal({ idSucursal: item.id, idUsuario: store.state.user.idUsuario })
        .then(response => {
          console.log(response.data)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', item.nombre + 'DataProducto' + moment().format('YYYYMMDDhmmss') + '.data')
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          exportandoDatos.value = false
        })
    }
    const filtro = ref({
      Id: null,
      IdTipoOrigenProducto: null,
      IdTipoProducto: null,
      IdUnidadMedida: null,
      IdModelo: null,
      IdMarca: null,
      IdColor: null,
      IdCategoria: null,
      IdSubCategoria: null,
      IdLinea: null,
      IdSubLinea: null,
      IdPais: null,
      Valor: '',
      IndPeso: null,
      IdTasaImpuesto: null,
      Cantidad: 0,
      Pagina: 1,
      Limite: 10,
      IdEmpresa: null,
      Columnas: null,
    })
    onBeforeMount(() => {
      consultarDatos()
    })
    const lista = ref([])
    const cargando = ref(false) 
    const filtroAux = ref('')
    
    
    const getOpciones = (opciones) => {
       
      filtro.value.Pagina = opciones.Pagina
      filtro.value.Limite = opciones.LimiteFilas 
      consultarDatos();
    }


    const consultarDatos = () => {
      cargando.value = true
      // lista.value = ''
      //Paginas.value = 0
      Cantidad.value = 0
      if (filtroAux.value != filtro.value) {
        Pagina.value = 1
        filtroAux.value = filtro.value
      }

      ProductoServices.ConsultarPaginado({
        ...filtro.value,
        IdEmpresa: store.state.user.idEmpresa,
      
      })
        .then(response => {
          console.log(response.data)
          if (response.data.estatus == true) {
            lista.value = response.data.datos.registros
            Paginas.value = response.data.datos.paginas
            Cantidad.value = response.data.datos.total
          } else {
            //alert('No hubo coincidiendia')
          }
        })
        .catch(err => {
          //alert(err)
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const exportExcel = columasSelect => {
      // console.log({...filtro.value,  Columnas: columasSelect})
      // console.log(`${config.api_master}producto/consultar/excel?${FuncionesGenerales.recorrerObjeto({...filtro.value,  Columnas: columasSelect})}`)
      window.open(
        `${config.api_master}producto/consultar/excel?${FuncionesGenerales.recorrerObjeto({
          ...filtro.value,
          Columnas: columasSelect,
        })}`,
      )
      // window.open(`${config.api_master}producto/consultar/excel?columnas=${columasSelect}`)
      // ProductoServices.ExportarExcel({...filtro.value,  columnas: columasSelect})
      //   .then(response => {
      //     console.log(response.data)
      //       console.log(response.data)
      //       fileDownload(response.data, 'filename.xlx');
      //   })
      //   .catch(err => {
      //     //alert(err)
      //   })
      //   .finally(() => {
      //     cargando.value = false
      //   })
    }

    const Limpiar = () => {
      filtro.value = []
      NombreRef.value.focus()
    }

    return { 
      consultarDatos,
      GetEditar,
      getOpciones,
      exportExcel,
      Limpiar,
      showFiltros,
      NombreRef,
      FuncionesGenerales,
      Paginas,
      Pagina,
      LimiteProps,
      Cantidad,
      cargando,
      lista,
      filtro,
      config,
      SelectModalRef,
      abrirModalSucursal,
      SelectModalValue,
      exportandoDatos,
      icons: {
        mdiPencil,
        mdiFilterOff,
        mdiFilter,
        mdiCheck,
        mdiCancel,
        mdiDatabaseExport
      },
      otrasAcciones,
      nombreLista,
      cabecera,
    }
  },
}
</script>