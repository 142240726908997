<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="300px">
      <v-card>
        <v-card-title>Selecciona una sucursal</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="select" column>
            <v-radio v-for="(item, index) in lista" :key="index" :label="item.nombre" :value="item.id"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialog = false"> Cerrar </v-btn>
          <v-btn color="primary" text @click="aceptar()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'

import SucursalServices from '@/api/servicios/SucursalServices.js'
import store from '@/store'
export default {
  props: {
    value: String,
    padre: Number,
    rules: Array,
    label: { type: String, default: 'Sucursal' },
  },
  setup(props, contex) {
    const lista = ref([])
    const select = ref(null)
    const cargando = ref(false)
    const dialog = ref(false)
    watch(props, () => {
      select.value = props.value
    })
    onBeforeMount(() => {
      cargarDatos()
      if (props.value != undefined) {
        select.value = props.value
      }
    })

    const abrirDialog = ()=>{
      dialog.value = true
    }

    const cargarDatos = () => {
      lista.value = []
      cargando.value = true
      try {
        SucursalServices.Consultar({ idEmpresa: store.state.user.idEmpresa })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargando.value = false
          })
      } catch (error) {
        cargando.value = false
      }
    }

    const aceptar = () => {
      const dato = lista.value.find(item=>item.id==select.value)
      contex.emit('input', dato)
      dialog.value = false
    }

    return {
      icons: {
        mdiAlphaCCircle,
        mdiPlusCircleOutline,
      },
      lista,
      select,
      cargando, 
      aceptar,
      dialog,
      abrirDialog
    }
  },
}
</script>