<template>
  <v-row>
    <v-col cols="12">
      <actualizar ref="ActualizarRef" @GetActualizarLista="GetActualizarLista"></actualizar>
    </v-col>

    <v-col cols="12">
      <Listar @GetEditar="GetEditar"></Listar>
    </v-col>
  </v-row>
</template>

<script>
import Actualizar from './componentes/Actualizar.vue'
import Listar from './componentes/Listar.vue'
import { ref } from '@vue/composition-api'
export default {
  components: {
    Actualizar,
    Listar,
  },
  setup() {
    const ActualizarRef = ref(null)

    const datos = ref({})
    const GetEditar = item => {
       
      ActualizarRef.value.CargarEditar(item)
    }
    const GetActualizarLista = () => {

    }

    return {
      ActualizarRef,
      datos,
      GetEditar,
      GetActualizarLista,
    }
  },
}
</script>
