<template>
  <v-stepper v-model="e1">
    <v-card-subtitle> Producto </v-card-subtitle>
    <v-stepper-header>
      <v-stepper-step step="1" editable> Producto </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2" editable> Adicionales </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="3" editable> Multimedia </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="4" editable> Costos/Precios </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-form ref="formProducto" v-model="formProductoValid" lazy-validation>
          <v-card-text>
            <v-row>
              <v-col lg="6">
                <strong class="primary--text">Descripcion</strong>
                <v-divider class="mb-4"></v-divider>
                <v-text-field :rules="reglas.Nombre" v-model="dato.Nombre" dense outlined label="Nombre"></v-text-field>
                <v-textarea v-model="dato.Descripcion" dense outlined label="Descripcion"></v-textarea>
                <v-textarea v-model="dato.DescripcionLarga" dense outlined label="Descripcion Larga"></v-textarea>

                <strong class="primary--text">Codigos</strong>
                <v-divider class="mb-4"></v-divider>

                <CodigoBarraInput :rules="reglas.Barra" v-model="dato.Barra"></CodigoBarraInput>

                <v-text-field v-model="dato.Referencia" dense outlined label="Referencia"></v-text-field>
                <v-text-field v-model="dato.ReferenciaFabrica" dense outlined label="Referencia Fabrica"></v-text-field>
                <v-switch v-model="dato.IndLote" :label="`Maneja Lotes`"></v-switch>
                <v-switch v-model="dato.IndPeso" :label="`Producto pesado`"></v-switch>
                <v-text-field v-show="dato.IndPeso" dense outlined label="Codigo Balanza"></v-text-field>
              </v-col>
              <v-col lg="6">
                <strong class="primary--text">Caracteristicas</strong>
                <v-divider class="mb-4"></v-divider>
                <TipoOrigenProdcutoSelect
                  :rules="reglas.IdTipoOrigenProducto"
                  v-model="dato.IdTipoOrigenProducto"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                >
                </TipoOrigenProdcutoSelect>
                <TipoProdcutoSelect
                  :rules="reglas.IdTipoProducto"
                  v-model="dato.IdTipoProducto"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                ></TipoProdcutoSelect>
                <TasaImpuestoSelect
                  :padre="1"
                  :rules="reglas.IdTipoProducto"
                  v-model="dato.IdTasaImpuesto"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                >
                </TasaImpuestoSelect>

                <UnidadMedidaSelect
                  :rules="reglas.IdUnidadMedida"
                  v-model="dato.IdUnidadMedida"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                ></UnidadMedidaSelect>
                <MarcaSelect
                  :rules="reglas.IdMarca"
                  :default="0"
                  v-model="dato.IdMarca"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                ></MarcaSelect>
                <ModeloSelect
                  :rules="reglas.IdModelo"
                  :default="0"
                  v-model="dato.IdModelo"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                ></ModeloSelect>
                <ColorSelect
                  :rules="reglas.IdColor"
                  :default="0"
                  v-model="dato.IdColor"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                ></ColorSelect>
                <CategoriaSelect
                  :rules="reglas.IdCategoria"
                  :default="0"
                  v-model="dato.IdCategoria"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                >
                </CategoriaSelect>
                <SubCategoriaSelect
                  :rules="reglas.IdSubCategoria"
                  :default="0"
                  v-model="dato.IdSubCategoria"
                  :disabled="dato.IdCategoria == null ? true : false"
                  :padre="dato.IdCategoria"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                >
                </SubCategoriaSelect>
                <LineaSelect
                  :rules="reglas.IdLinea"
                  v-model="dato.IdLinea"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                ></LineaSelect>
                <SubLineaSelect
                  :rules="reglas.IdSubLinea"
                  v-model="dato.IdSubLinea"
                  :disabled="dato.IdLinea == null ? true : false"
                  :padre="dato.IdLinea"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                ></SubLineaSelect>
                <PaisSelect
                  :rules="reglas.IdPais"
                  :default="117"
                  v-model="dato.IdPais"
                  class="mb-4"
                  :clearable="true"
                  :outlined="true"
                ></PaisSelect>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <div class="text-right">
          <v-btn class="mx-1 my-1" small @click="limpiar()"> Limpiar </v-btn>
          <v-btn class="mx-1 my-1" small color="primary" @click="Siguiente(2)"> Continuar </v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-form ref="formProducto2" v-model="formProductoValid2" lazy-validation>
          <v-card-text>
            <v-row>
              <v-col lg="6">
                <v-textarea rows="3" dense outlined label="CPE" v-model="dato.CPE"></v-textarea>
                <v-textarea
                  rows="3"
                  dense
                  outlined
                  label="Registro Sanitario"
                  v-model="dato.RegistroSanitario"
                ></v-textarea>
              </v-col>
              <v-col lg="6">
                <strong class="primary--text">Contenido del producto</strong>
                <v-select
                  class="mb-4 mt-2"
                  v-model="dato.IdProductoIndividual"
                  :items="['No Asociado', '1', '2', '3']"
                  label="Producto Individual"
                  dense
                  outlined
                  hide-details="auto"
                ></v-select>
                <UnidadMedidaSelect
                  v-model="dato.IdUnidadMedidaEmpaque"
                  class="mb-4 mt-2"
                  :clearable="true"
                  :outlined="true"
                ></UnidadMedidaSelect>
                <v-text-field v-model="dato.CantidadEmpaque" label="Cantidad Contenido" dense outlined></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <div class="text-right">
          <v-btn class="mx-1 my-1" small @click="Siguiente(1)"> Atras </v-btn>
          <v-btn class="mx-1 my-1" small color="primary" @click="Siguiente(3)"> Continuar </v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-content step="-1">
        <v-card-text>
          <v-row>
            <span>Preguntar contenido</span>
          </v-row>
        </v-card-text>

        <div class="text-right">
          <v-btn class="mx-1 my-1" small @click="Siguiente(2)"> Atras </v-btn>
          <v-btn class="mx-1 my-1" small color="primary" @click="Siguiente(4)"> Continuar </v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-content step="4">
        <v-expansion-panels multiple class="mb-6" v-model="panel" v-show="dato.ProductoSucursal.length > 1">
          <v-expansion-panel key="-1">
            <v-expansion-panel-header color="primary" class="white--text">Global</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-6">
                <v-col cols="6">
                  <ProveedorSelect
                    v-if="sucursalesGlobal.Proveedor.id == undefined"
                    :default="0"
                    v-model="sucursalesGlobal.Proveedor"
                    :jsonRespueta="true"
                    class="mb-4"
                    :rules="reglasSucursales.Proveedor"
                    :clearable="true"
                    :outlined="true"
                  >
                  </ProveedorSelect>
                  <span v-else>
                    <h4>
                      Proveedor
                      <v-btn icon color="pink" @click="sucursalesGlobal.Proveedor = {}">
                        <v-icon>{{ icons.mdiStorefrontCheckOutline }}</v-icon>
                      </v-btn>
                    </h4>
                    <span>
                      {{ sucursalesGlobal.Proveedor.tipoDocumento.abreviatura }}-{{
                        sucursalesGlobal.Proveedor.documento
                      }}</span
                    ><br />
                    <span>{{ sucursalesGlobal.Proveedor.razonSocial }}</span>
                  </span>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    @keyup="calculoCostosPVP(sucursalesGlobal, 'Costo')"
                    :rules="reglasSucursales.Costo"
                    v-model="sucursalesGlobal.Costo"
                    label="Costo 1"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    @keyup="calculoCostosPVP(sucursalesGlobal, 'Costo2')"
                    :rules="reglasSucursales.Costo2"
                    v-model="sucursalesGlobal.Costo2"
                    label="Costo 2"
                    dense
                    outlined
                  ></v-text-field>

                  <v-text-field
                  :suffix="`${FuncionesGenerales.roundToTwo((sucursalesGlobal.FactorCosto2 - 1) * 100 ,2)}%`"
                    @keyup="calculoCostosPVP(sucursalesGlobal, 'FactorCosto2')"
                    :rules="reglasSucursales.FactorCosto2"
                    v-model="sucursalesGlobal.FactorCosto2"
                    label="Factor 2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    @keyup="calculoCostosPVP(sucursalesGlobal, 'Costo3')"
                    :rules="reglasSucursales.Costo3"
                    v-model="sucursalesGlobal.Costo3"
                    label="Costo 3"
                    dense
                    outlined
                  ></v-text-field>

                  <v-text-field
                    :suffix="`${FuncionesGenerales.roundToTwo((sucursalesGlobal.FactorCosto3 - 1) * 100 ,2)}%`"
                    @keyup="calculoCostosPVP(sucursalesGlobal, 'FactorCosto3')"
                    v-model="sucursalesGlobal.FactorCosto3"
                    label="Factor 3"
                    dense
                    :rules="reglasSucursales.FactorCosto3"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                  :suffix="`${FuncionesGenerales.roundToTwo((sucursalesGlobal.FactorVenta - 1) * 100 ,2)}%`"
                    @keyup="calculoCostosPVP(sucursalesGlobal, 'FactorVenta')"
                    :rules="reglasSucursales.FactorVenta"
                    v-model="sucursalesGlobal.FactorVenta"
                    label="Factor Venta"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    @keyup="calculoCostosPVP(sucursalesGlobal, 'PVP')"
                    :rules="reglasSucursales.PVP"
                    v-model="sucursalesGlobal.PVP"
                    label="PVP"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch v-model="sucursalesGlobal.IndActivoVenta" :label="`Activo Venta`"></v-switch>
                </v-col>
                <v-col>
                  <v-switch v-model="sucursalesGlobal.IndActivoCompra" :label="`Activo Compra`"></v-switch>
                </v-col>
                <v-col>
                  <v-switch v-model="sucursalesGlobal.IndIVA" :label="`Activo IVA`"></v-switch>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-form ref="formProducto4" v-model="formProductoValid4" lazy-validation>
          <v-expansion-panels multiple class="mb-6" v-model="panel2">
            <v-expansion-panel v-for="(item, i) in dato.ProductoSucursal" :key="i">
              <v-expansion-panel-header color="secondary" class="white--text">{{
                item.Nombre
              }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-6">
                  <v-col cols="6">
                    <ProveedorSelect
                      v-if="item.Proveedor.id == undefined"
                      :default="0"
                      v-model="item.Proveedor"
                      :jsonRespueta="true"
                      class="mb-4"
                      :clearable="true"
                      :outlined="true"
                      :rules="reglasSucursales.Proveedor"
                    ></ProveedorSelect>
                    <span v-else>
                      <h4>
                        Proveedor
                        <v-btn icon color="pink" @click="item.Proveedor = {}">
                          <v-icon>{{ icons.mdiStorefrontCheckOutline }}</v-icon>
                        </v-btn>
                      </h4>
                      <span> {{ item.Proveedor.tipoDocumento.abreviatura }}-{{ item.Proveedor.documento }}</span
                      ><br />
                      <span>{{ item.Proveedor.razonSocial }}</span>
                    </span>
                  </v-col>
                  <v-col>
                    <v-text-field
                      @keyup="calculoCostosPVP(item, 'Costo')"
                      v-model="item.Costo"
                      label="Costo 1"
                      dense
                      outlined
                      :rules="reglasSucursales.Costo"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      @keyup="calculoCostosPVP(item, 'Costo2')"
                      v-model="item.Costo2"
                      label="Costo 2"
                      dense
                      outlined
                      :rules="reglasSucursales.Costo2"
                    ></v-text-field>

                    <v-text-field
                    :suffix="`${FuncionesGenerales.roundToTwo((item.FactorCosto2 - 1) * 100 ,2)}%`"
                      @keyup="calculoCostosPVP(item, 'FactorCosto2')"
                      :rules="reglasSucursales.FactorCosto2"
                      v-model="item.FactorCosto2"
                      label="Factor 2"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      @keyup="calculoCostosPVP(item, 'Costo3')"
                      v-model="item.Costo3"
                      :rules="reglasSucursales.Costo3"
                      label="Costo 3"
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                    :suffix="`${FuncionesGenerales.roundToTwo((item.FactorCosto3 - 1) * 100 ,2)}%`"
                      @keyup="calculoCostosPVP(item, 'FactorCosto3')"
                      :rules="reglasSucursales.FactorCosto3"
                      v-model="item.FactorCosto3"
                      label="Factor 3"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                    :suffix="`${FuncionesGenerales.roundToTwo((item.FactorVenta - 1) * 100 ,2)}%`"
                      @keyup="calculoCostosPVP(item, 'FactorVenta')"
                      :rules="reglasSucursales.FactorVenta"
                      v-model="item.FactorVenta"
                      label="Factor Venta"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      @keyup="calculoCostosPVP(item, 'PVP')"
                      v-model="item.PVP"
                      label="PVP"
                      :rules="reglasSucursales.PVP"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch v-model="item.IndActivoVenta" :label="`Activo Venta`"></v-switch>
                  </v-col>
                  <v-col>
                    <v-switch v-model="item.IndActivoCompra" :label="`Activo Compra`"></v-switch>
                  </v-col>
                  <v-col>
                    <v-switch v-model="item.IndIVA" :label="`Activo IVA`"></v-switch>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>

        <v-btn class="mx-1 my-1" small @click="limpiar()"> Atras </v-btn>

        <v-btn class="mx-1 my-1" small color="primary" @click="Siguiente(5)"> Finalizar </v-btn>
      </v-stepper-content>
      <!-- Step 5 Carga de imaganes del producto -->
      <v-stepper-content step="3">
        <v-row>
          <v-col class="text-center">
            <AddImages ref="AddImagesThumbnail" @sendImg="sendImgThumbnail" _selected="true" title="Miniatura" />
            <small v-if="dato.Thumbnail.id == undefined" class="error--text">* Es requerido</small>
          </v-col>
          <v-col class="text-center">
            <AddImages
              ref="AddImagesThumbnailBack"
              @sendImg="sendImgThumbnail_back"
              _selected="true"
              title="Miniatura Back"
            />
            <small v-if="dato.Thumbnail_back.id == undefined" class="error--text">* Es requerido</small>
          </v-col>
          <v-col class="text-center">
            <AddImages
              ref="AddImagesImages"
              @sendImg="sendImgImages"
              :_multiSelected="true"
              :_selected="true"
              title="Carrucel"
            />
            <small v-if="dato.Images.length == 0" class="error--text">* Es requerido</small>
          </v-col>
        </v-row>
        <div class="text-right mt-2">
          <v-btn text @click="Siguiente(2)"> Atras </v-btn>
          <v-btn color="primary" @click="Siguiente(4)"> Continuar </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import UnidadMedidaSelect from '@/views/sistema/producto/maestro/unidad-medida/componentes/Select.vue'
import MarcaSelect from '@/views/sistema/producto/maestro/marca/componentes/Select.vue'
import ModeloSelect from '@/views/sistema/producto/maestro/modelo/componentes/Select.vue'
import ColorSelect from '@/views/sistema/producto/maestro/color/componentes/Select.vue'
import CategoriaSelect from '@/views/sistema/producto/maestro/categoria/componentes/Select.vue'
import SubCategoriaSelect from '@/views/sistema/producto/maestro/sub-categoria/componentes/Select.vue'
import LineaSelect from '@/views/sistema/producto/maestro/linea/componentes/Select.vue'
import SubLineaSelect from '@/views/sistema/producto/maestro/sub-linea/componentes/Select.vue'
import PaisSelect from '@/views/sistema/producto/maestro/pais/componentes/Select.vue'
import TipoOrigenProdcutoSelect from '@/views/sistema/producto/maestro/tipo-origen-producto/componentes/Select.vue'
import TipoProdcutoSelect from '@/views/sistema/producto/maestro/tipo-producto/componentes/Select.vue'
import TasaImpuestoSelect from '@/views/sistema/producto/maestro/tasa-impuesto/componentes/Select.vue'
import ProveedorSelect from '@/views/sistema/producto/catalogo/proveedor/componentes/Select.vue'
import FuncionesGenerales from '@/funciones/funciones.js'
import ProductoServices from '@/api/servicios/ProductoServices'
import CodigoBarraInput from '../../../maestro/codigo-barra/componente/input.vue'
import { mdiFormatListCheckbox } from '@mdi/js'
import AddImages from '@/views/sistema/configuracion/imagen/componentes/AddImages.vue'
export default {
  components: {
    UnidadMedidaSelect,
    MarcaSelect,
    ModeloSelect,
    ColorSelect,
    CategoriaSelect,
    SubCategoriaSelect,
    LineaSelect,
    SubLineaSelect,
    PaisSelect,
    TipoOrigenProdcutoSelect,
    TipoProdcutoSelect,
    TasaImpuestoSelect,
    ProveedorSelect,
    CodigoBarraInput,
    AddImages,
  },
  setup(props, context) {
    const mdiPackageVariantClosedPlus =
      'M13 19.3V12.6L19 9.2V13C19.7 13 20.4 13.1 21 13.4V7.5C21 7.1 20.8 6.8 20.5 6.6L12.6 2.2C12.4 2.1 12.2 2 12 2S11.6 2.1 11.4 2.2L3.5 6.6C3.2 6.8 3 7.1 3 7.5V16.5C3 16.9 3.2 17.2 3.5 17.4L11.4 21.8C11.6 21.9 11.8 22 12 22S12.4 21.9 12.6 21.8L13.5 21.3C13.2 20.7 13.1 20 13 19.3M12 4.2L18 7.5L16 8.6L10.1 5.2L12 4.2M11 19.3L5 15.9V9.2L11 12.6V19.3M12 10.8L6 7.5L8 6.3L14 9.8L12 10.8M20 15V18H23V20H20V23H18V20H15V18H18V15H20Z'
    const mdiStorefrontCheckOutline =
      'M5.06 3C4.63 3 4.22 3.14 3.84 3.42S3.24 4.06 3.14 4.5L2.11 8.91C1.86 10 2.06 10.92 2.69 11.73C2.8 11.84 2.9 11.94 3 12.03V19C3 19.5 3.2 20 3.61 20.39S4.5 21 5 21H12.8C12.45 20.39 12.2 19.72 12.08 19H5V13C5.07 13 5.15 13 5.22 13C6.16 13 6.91 12.59 7.47 12.05C8.1 12.67 8.86 13 9.8 13C10.64 13 11.44 12.63 12 12.07C12.68 12.7 13.45 13 14.3 13C15.17 13 15.91 12.67 16.54 12.05C17.11 12.62 17.86 13 18.81 13S20.43 12.65 21 12.06L21.13 11.92C21.18 11.87 21.23 11.82 21.28 11.77C21.94 10.95 22.14 10 21.89 8.91L20.86 4.5C20.73 4.06 20.5 3.7 20.13 3.42C19.77 3.14 19.38 3 18.94 3M18.89 4.97L19.97 9.38C20.06 9.81 19.97 10.2 19.69 10.55C19.44 10.86 19.13 11 18.75 11C18.44 11 18.17 10.9 17.95 10.66C17.73 10.43 17.61 10.16 17.58 9.84L16.97 5M5.06 5H7.03L6.42 9.84C6.3 10.63 5.91 11 5.25 11C4.84 11 4.53 10.86 4.31 10.55C4.03 10.2 3.94 9.81 4.03 9.38M9.05 5H11V9.7C11 10.05 10.89 10.35 10.64 10.62C10.39 10.88 10.08 11 9.7 11C9.36 11 9.07 10.88 8.84 10.59S8.5 10 8.5 9.66V9.5M13 5H14.95L15.5 9.5C15.58 9.92 15.5 10.27 15.21 10.57C14.95 10.87 14.61 11 14.2 11C13.89 11 13.61 10.88 13.36 10.62C13.11 10.35 13 10.05 13 9.7M17 21L14.25 18L15.41 16.84L17 18.43L20.59 14.84L21.75 16.25Z'
    const guardando = ref(false)
    const formProducto = ref(null)
    const formProductoValid = ref(true)
    const formProductoValid2 = ref(true)
    const formProducto2 = ref(null)
    const formProductoValid4 = ref(true)
    const formProducto4 = ref(null)
    const panel = ref([])
    const panel2 = ref([])
    const AddImgRef = ref(null)
    const datoInit = {
      Id: -1,
      IdTipoOrigenProducto: null,
      IdTipoProducto: null,
      IdProductoIndividual: null,
      IdUnidadMedida: null,
      IdUnidadMedidaEmpaque: null,
      CantidadEmpaque: 0,
      IdModelo: 0,
      IdMarca: 0,
      IdColor: 0,
      IdCategoria: 0,
      IdSubCategoria: 0,
      IdLinea: null,
      IdSubLinea: null,
      IdPais: 117,
      Barra: '',
      Referencia: '',
      ReferenciaFabrica: '',
      Nombre: '',
      Descripcion: '',
      DescripcionLarga: '',
      IndPeso: false,
      IndLote: false,
      CPE: '',
      RegistroSanitario: '',
      IdTasaImpuesto: null,
      ProductoSucursal: [],
      Thumbnail: {},
      Thumbnail_back: {},
      Images: [],
    }
    const AddImagesThumbnail = ref(null)
    const AddImagesThumbnailBack = ref(null)
    const AddImagesImages = ref(null)
    const dato = ref(JSON.parse(JSON.stringify(datoInit)))
    const sucursalesGlobal = ref({
      IdProveedor: 0,
      Costo: 0,
      FactorCosto2: 1,
      Costo2: 0,
      FactorCosto3: 1,
      Costo3: 0,
      FactorVenta: 1,
      PVP: 0,
      IndActivoVenta: true,
      IndActivoCompra: true,
      IndIVA: true,
      Proveedor: { tipoDocumento: { abreviatura: '' } },
    })

    const sendImgThumbnail = items => {
      dato.value.Thumbnail = items.img[0]
    }
    const sendImgThumbnail_back = items => {
      dato.value.Thumbnail_back = items.img[0]
    }
    const sendImgImages = items => {
      dato.value.Images = items.img
    }

    watch(sucursalesGlobal.value, () => {
      dato.value.ProductoSucursal.forEach(item => {
        item.Proveedor = sucursalesGlobal.value.Proveedor
        item.IdProveedor = sucursalesGlobal.value.IdProveedor
        item.Costo = sucursalesGlobal.value.Costo
        item.FactorCosto2 = sucursalesGlobal.value.FactorCosto2
        item.Costo2 = sucursalesGlobal.value.Costo2
        item.FactorCosto3 = sucursalesGlobal.value.FactorCosto3
        item.Costo3 = sucursalesGlobal.value.Costo3
        item.FactorVenta = sucursalesGlobal.value.FactorVenta
        item.PVP = sucursalesGlobal.value.PVP
        item.IndActivoVenta = sucursalesGlobal.value.IndActivoVenta
        item.IndActivoCompra = sucursalesGlobal.value.IndActivoCompra
        item.IndIVA = sucursalesGlobal.value.IndIVA
      })
    })

    onBeforeMount(() => {
      //cargar sucursales
      panel.value.push(0)
      store.state.user.sucursales.forEach((element, i) => {
        dato.value.ProductoSucursal.push({
          Id: -1,
          Nombre: element.nombre,
          IdProducto: dato.value.Id,
          IdSucursal: element.id,
          IndActivo: true,
          Proveedor: { tipoDocumento: { abreviatura: '' } },
          IdProveedor: 0,
          Costo: 0,
          FactorCosto2: 1,
          Costo2: 0,
          FactorCosto3: 1,
          Costo3: 0,
          FactorVenta: 1,
          PVP: 0,
          IndActivoVenta: true,
          IndActivoCompra: true,
          IndIVA: true,
        })

        
      })
       panel2.value =[]
    })

    const reglasSucursales = {
      Proveedor: [v => (v !== null && v !== {}) || 'Proveedor es requerido'],
      Costo: [
        v => (v !== '' && v !== null) || 'Costo es requerido',
        v => !/[^0-9\.]|(?<=.)-|\.(?=.*\.)/.test(v) || 'No es un valor valido',
      ],
      FactorCosto2: [
        v => (v !== '' && v !== null) || 'FactorCosto2 es requerido',
        v => !/[^0-9\.-]|(?<=.)-|\.(?=.*\.)/.test(v) || 'No es un valor valido',
      ],
      Costo2: [
        v => (v !== '' && v !== null) || 'Costo2es requerido',
        v => !/[^0-9\.]|(?<=.)-|\.(?=.*\.)/.test(v) || 'No es un valor valido',
      ],
      FactorCosto3: [
        v => (v !== '' && v !== null) || 'FactorCosto3 es requerido',
        v => !/[^0-9\.-]|(?<=.)-|\.(?=.*\.)/.test(v) || 'No es un valor valido',
      ],
      Costo3: [
        v => (v !== '' && v !== null) || 'Costo3 es requerido',
        v => !/[^0-9\.]|(?<=.)-|\.(?=.*\.)/.test(v) || 'No es un valor valido',
      ],
      FactorVenta: [
        v => (v !== '' && v !== null) || 'FactorVenta es requerido',
        v => !/[^0-9\.-]|(?<=.)-|\.(?=.*\.)/.test(v) || 'No es un valor valido',
      ],
    }

    const reglas = {
      IdTipoOrigenProducto: [v => v !== null || 'Tipo Origen Producto es requerido'],
      IdTipoProducto: [v => v !== null || 'Tipo Producto es requerido'],
      IdUnidadMedida: [v => v !== null || 'Unidad Medida es requerido'],
      IdModelo: [v => v !== null || 'Modelo es requerido'],
      IdMarca: [v => v !== null || 'Marca es requerido'],
      IdColor: [v => v !== null || 'Color es requerido'],
      IdCategoria: [v => v !== null || 'Categoria es requerido'],
      IdSubCategoria: [v => v !== null || 'SubCategoria es requerido'],
      IdLinea: [v => v !== null || 'Linea es requerido'],
      IdSubLinea: [v => v !== null || 'SubLinea es requerido'],
      IdPais: [v => !!v || 'Pais es requerido'],
      Barra: [v => !!v || 'Barra es requerido'],
      Nombre: [v => !!v || 'Nombre es requerido'],
    }

    const validarFormProducto = () => {
      let val = formProducto.value?.validate()

      return val
    }
    const validarFormProducto2 = () => {
      let val = formProducto2.value?.validate()

      return val
    }

    const validarFormProducto3 = () => {
      return true
      if (dato.value.Thumbnail.id == undefined) {
        return false
      } else if (dato.value.Thumbnail_back.id == undefined) {
        return false
      } else if (dato.value.Images.length == 0) {
        return false
      } else {
        return true
      }
      
    }
    const validarFormProducto4 = () => {
      let val = formProducto4.value?.validate()

      return val
    }
    const tabs = [
      { title: 'Producto', icon: mdiPackageVariantClosedPlus },
      { title: 'ProductoSucursal', icon: mdiStorefrontCheckOutline },
      { title: 'Adicionales', icon: mdiFormatListCheckbox },
    ]
    const e1 = ref(1)
    const items = ref(['Foo', 'Bar', 'Fizz', 'Buzz'])

    const getCategoriaSelect = item => {
      dato.value.IdCategoria = item.id
      ////alert(dato.value.IdCategoria)
    }
    const limpiar = () => {
      dato.value = JSON.parse(JSON.stringify(datoInit))
      formProducto.value.resetValidation()
      formProducto2.value.resetValidation()
      //formProducto3.value.resetValidation();
      formProducto4.value.resetValidation()
      panel.value.push(0)
      store.state.user.sucursales.forEach((element, i) => {
        dato.value.ProductoSucursal.push({
          Id: -1,
          Nombre: element.nombre,
          IdProducto: dato.value.Id,
          IdSucursal: element.id,
          IndActivo: true,
          Proveedor: { tipoDocumento: { abreviatura: '' } },
          IdProveedor: 0,
          Costo: 0,
          FactorCosto2: 1,
          Costo2: 0,
          FactorCosto3: 1,
          Costo3: 0,
          FactorVenta: 1,
          PVP: 0,
          IndActivoVenta: true,
          IndActivoCompra: true,
          IndIVA: true,
        })

        //panel2.value.push(i)
      })

      sucursalesGlobal.value.Proveedor = {}
      sucursalesGlobal.value.IdProveedor = 0
      sucursalesGlobal.value.Costo = 0
      sucursalesGlobal.value.FactorCosto2 = 1
      sucursalesGlobal.value.Costo2 = 0
      sucursalesGlobal.value.FactorCosto3 = 1
      sucursalesGlobal.value.Costo3 = 0
      sucursalesGlobal.value.FactorVenta =1
      sucursalesGlobal.value.PVP = 0
      sucursalesGlobal.value.IndActivoVenta = true
      sucursalesGlobal.value.IndActivoCompra = true
      sucursalesGlobal.value.IndIVA = true
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const Guardar = () => {
      guardando.value = true

      try {
        ProductoServices.Actualizar(store.state.user.idUsuario, dato.value)
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
              ActualizarLista(response.data.datos.datos)
              limpiar()
            } else {
              store.commit('setAlert', {
                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            console.log(error)
            store.commit('setAlert', {
              message: error,
              type: 'error',
            })
          })
          .finally(() => {
            guardando.value = false
          })
      } catch (error) {
        ////alert(error)
        store.commit('setAlert', {
          message: error,
          type: 'error',
        })
      }
    }
    const Siguiente = num => {
      let validar = false
      ////alert(num)
      if (num > e1.value) {
        if (num == 2) {
          //validar 1
          validar = validarFormProducto()
        } else if (num == 3) {
          //validar 2
          validar = validarFormProducto2()
          //validar = true
        } else if (num == 4) {
          //validar 2
          validar = validarFormProducto3()
          //validar = true
        } else {
          if (!validarFormProducto()) {
            e1.value = 1
          } else if (!validarFormProducto2()) {
            e1.value = 2
          } else if (!validarFormProducto3()) {
            ////alert(validarFormProducto4())
            e1.value = 3
          } else if (!validarFormProducto4()) {
            ////alert(validarFormProducto4())
            e1.value = 4
          } else {
            ////alert("asq")
            Guardar()
          }
        }
        if (validar) {
          e1.value = num
        }
      } else {
        e1.value = num
      }
    }
    const CargarEditar = item => {
      console.log('CargarEditar')
      console.log(item)
      var _productoSucural = []
      item.productoSucursal.forEach(item => {
        _productoSucural.push({
          Id: item.id,
          Nombre: item.sucursal.nombre,
          IdProducto: item.idProducto,
          IdSucursal: item.sucursal.id,
          IndActivo: item.indActivo,
          Proveedor: item.proveedor,
          IdProveedor: item.idProveedor,
          Costo: item.costo,
          FactorCosto2: item.factorCosto2,
          Costo2: item.costo2,
          FactorCosto3: item.factorCosto3,
          Costo3: item.costo3,
          FactorVenta: item.factorVenta,
          PVP: item.pvp,
          IndActivoVenta: item.indActivoVenta,
          IndActivoCompra: item.indActivoCompra,
          IndIVA: item.indIVA,
        })
      })

      dato.value = {
        Id: item.id,
        IdTipoOrigenProducto: item.idTipoOrigenProducto,
        IdTipoProducto: item.idTipoProducto,
        IdProductoIndividual: item.idProductoIndividual,
        IdUnidadMedida: item.idUnidadMedida,
        IdUnidadMedidaEmpaque: item.idUnidadMedidaEmpaque,
        CantidadEmpaque: item.cantidadEmpaque,
        IdModelo: item.idModelo,
        IdMarca: item.idMarca,
        IdColor: item.idColor,
        IdCategoria: item.categoria.id,
        IdSubCategoria: item.idSubCategoria,
        IdLinea: item.linea.id,
        IdSubLinea: item.idSubLinea,
        IdPais: item.idPais,
        Barra: item.barra,
        Referencia: item.referencia,
        ReferenciaFabrica: item.referenciaFabrica,
        Nombre: item.nombre,
        Descripcion: item.descripcion,
        DescripcionLarga: item.descripcionLarga,
        IndPeso: item.indPeso,
        IndLote: item.indLote,
        CPE: item.CPE,
        RegistroSanitario: item.registroSanitario,
        IdTasaImpuesto: item.idTasaImpuesto,
        ProductoSucursal: _productoSucural,
        Thumbnail: item.thumbnail ?? {},
        Thumbnail_back: item.thumbnail_back ?? {},
        Images: item.images ?? [],
      }
   
      AddImagesThumbnail.value.imagenes = [item.thumbnail]
      AddImagesThumbnailBack.value.imagenes = [item.thumbnail_back]
      AddImagesImages.value.imagenes = item.images
      goTo(0)
    }
    const calculoCostosPVP = (item, campo) => {
      switch (campo) {
        case 'Costo':
          item.Costo2 = FuncionesGenerales.roundToTwo(item.Costo * item.FactorCosto2)

          item.Costo3 = FuncionesGenerales.roundToTwo(item.Costo2 * item.FactorCosto3)

          item.PVP = FuncionesGenerales.roundToTwo(item.Costo3 * item.FactorVenta)
          break
        case 'Costo2':
          item.FactorCosto2 = FuncionesGenerales.roundToTwo(item.Costo2 / item.Costo)

          item.Costo3 = FuncionesGenerales.roundToTwo(FuncionesGenerales.roundToTwo(item.Costo2 * item.FactorCosto3))

          item.PVP = FuncionesGenerales.roundToTwo(item.Costo3 * item.FactorVenta)
          break

        case 'FactorCosto2':
          item.Costo2 = FuncionesGenerales.roundToTwo(item.Costo * item.FactorCosto2)
          item.Costo3 = FuncionesGenerales.roundToTwo(item.Costo2 * item.FactorCosto3)
          item.PVP = FuncionesGenerales.roundToTwo(item.Costo3 * item.FactorVenta)
          break
        case 'FactorCosto3':
          item.Costo3 = FuncionesGenerales.roundToTwo(item.Costo2 * item.FactorCosto3)
          item.PVP = FuncionesGenerales.roundToTwo(item.Costo3 * item.FactorVenta)
          break

        case 'Costo3':
          100
          item.FactorCosto3 = FuncionesGenerales.roundToTwo(item.Costo3 / item.Costo2)
          item.PVP = FuncionesGenerales.roundToTwo(item.Costo3 * item.FactorVenta)
          break
        case 'FactorVenta':
          item.PVP = FuncionesGenerales.roundToTwo(item.Costo3 * item.FactorVenta)
          break

        case 'PVP':
          item.FactorVenta = FuncionesGenerales.roundToTwo(item.PVP / item.Costo3)
          break
        default:
        // default statements
      }
      if (campo == 'Costo') {
      }
    }
    return {
      FuncionesGenerales,
      guardando,
      formProducto,
      formProductoValid,
      formProducto2,
      formProductoValid2,
      formProducto4,
      formProductoValid4,
      tabs,
      e1,
      items,
      dato,
      getCategoriaSelect,
      reglas,
      Siguiente,
      validarFormProducto,
      panel,
      store,
      calculoCostosPVP,
      sucursalesGlobal,
      reglasSucursales,
      panel2,
      icons: {
        mdiStorefrontCheckOutline,
      },
      limpiar,
      AddImgRef,
      sendImgThumbnail,
      sendImgThumbnail_back,
      sendImgImages,
      CargarEditar,
      AddImagesThumbnail,
      AddImagesThumbnailBack,
      AddImagesImages,
    }
  },
}
</script>